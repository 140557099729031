<template>
  <div class="box-card" id="account-box">
    <div class="page-title">登录</div>
    <div>
      <el-form class="input-box" ref="login" :rules="rules" :model="dataForm" @keyup.enter.native="handleLogin()">
        <el-form-item prop="username">
          <el-input v-model="dataForm.username" placeholder="请输入手机号码" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="dataForm.password" placeholder="密码" show-password />
        </el-form-item>
        <div class="form-group" style="display: flex;">
          <div>
              <el-input type="text" id="code" v-model="code" class="code"  placeholder="请输入验证码" />
          </div>
          <div class="login-code" @click="refreshCode">
            <!--验证码组件-->
            <s-identify :identifyCode="identifyCode"></s-identify>
          </div>
        </div>

        <div class="greyColor" style="margin:15px 0 5px">
          <el-checkbox style="float:left" v-model="remember" @change="rememberChange">记住我</el-checkbox>
          <span class="forgetBtn" @click="$router.push('/forgot')">忘记密码</span>
          <div class="clear"/>
        </div>

      </el-form>

      <div>
        <el-button @click="toSignup" :disabled="!isMatchTime">注册</el-button>

        <el-button class="login-button" type="primary" v-on:click="handleLogin" :loading="signinLoading" >登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getMatchTime } from '@/api'
import { getFullTimeNow } from '@/utils/utils'
import SIdentify from '@/components/sidentify'
export default {
  name: 'signin',
  data () {
    return {
      isMatchTime: false, //是否在比赛时间内，默认为否
      remember: true,
      dataForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入帐号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      signinLoading: false, // 登录按钮加载动画
      //验证码专用
      identifyCodes: '1234567890',
      identifyCode: '',
      code: ''//text框输入的验证码
      //验证码专用结束
    }
  },
  computed: {

  },
  created () {
    const remember = localStorage.getItem('remember')
    if (remember === null || remember === undefined) {
      this.remember = true
    } else {
      this.remember = Boolean(remember)
    }

    const username = localStorage.getItem('username')
    this.dataForm.username = username || ''

    getMatchTime().then(res => {
      let timeNow = getFullTimeNow()
      let timeSlot = res.data
      if (timeNow > timeSlot[0] && timeNow < timeSlot[1]) {
        this.isMatchTime = true
      }
    })

  },
  mounted () {
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
    
    if (this.$router.currentRoute.params?.username) {
      this.dataForm.username = this.$router.currentRoute.params.username
    }
  },
  methods: {
    handleLogin () {

      this.$refs.login.validate((valid) => {
        if (valid) {
          if (this.code === '') {
            this.$message.error('请输入验证码')
            return
          }

          if (this.identifyCode !== this.code) {
            this.code = ''
            this.refreshCode()
            this.$alert('请输入正确的验证码', '系统消息', {
              confirmButtonText: '确定',
              type: 'error'
            });
            return
          }
          this.signinLoading = true
          this.$store.dispatch('LOGIN_BY_USERNAME', this.dataForm).then((res) => {
            if (this.remember) {
              localStorage.setItem('username', this.dataForm.username)
            } else {
              localStorage.removeItem('username')
            }
            if (res.authority === 1) {
              this.$router.push('/admin')
            } else {
              this.$router.push('/home')
            }
          }).catch(error => {
            this.code = ''
            this.refreshCode()
            this.$alert(error, '系统消息', {
              confirmButtonText: '确定',
              type: 'error'
            });
            // this.$message.error(error);
          }).finally(() => {
            this.signinLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    rememberChange (val) {
      localStorage.setItem('remember', val)
    },

    //验证码
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    refreshCode () {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes [
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    toSignup () {
      if (!this.isMatchTime) {
        this.$alert('不在规定时间内，无法注册', '系统消息', {
          confirmButtonText: '确定',
          type: 'error'
        });
        return 0
      }
      this.$router.push({ name: 'signup' });
    }

  },
  components: {
    SIdentify
  }
}
</script>

<style scoped lang="scss">
.clear {
  clear: both;
}

.greyColor {
  color: #909399
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 25px 0px;
}
.input-box {
  width: 400px;
  /* margin-top: 10px; */
  margin: auto;
}
#account-box {
  text-align: center;
}
.login-button {
  margin-top: 10px;
  margin-left: 10px;
}

/*验证码样式*/
.code{
  width:265px;
}
.login-code{
  cursor: pointer;
}

.forgetBtn {
  float: right;
}

.forgetBtn:hover {
  color: #409EFF;
  cursor: pointer;
}

@media screen and (max-width: 405px) {
    .input-box {
      width: 350px;
    }
    .code {
      width: 215px;
    }
}
</style>
